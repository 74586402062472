import React, { useState } from "react";
import { FiEdit, FiTrash, FiCheck, FiX, FiMessageSquare } from "react-icons/fi";

interface ConversationItemProps {
  id: number;
  text: string;
  onEdit: () => void;
  onDelete: () => void;
  onSelect: () => void;
  onSaveEdit: (newTitle: string) => void; // New prop to handle saving the new title
  isSelected: boolean;
}

const ConversationItem: React.FC<ConversationItemProps> = ({
  id, // Unique ID for each conversation
  text,
  onEdit,
  onDelete,
  onSelect,
  onSaveEdit,
  isSelected,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState(text);

  const handleSave = () => {
    onSaveEdit(editValue); // Call the save handler passed in as a prop
    setIsEditing(false);
  };

  const handleCancel = () => {
    setEditValue(text); // Revert to the original text
    setIsEditing(false);
  };

  return (
    <div
      className={`flex items-center justify-between p-3 rounded-lg mb-2 cursor-pointer transition hover:bg-gray-200 ${
        isSelected ? "bg-gray-200" : "bg-white"
      }`}
      onClick={onSelect}
    >
      <div className="flex items-center cursor-pointer">
        {isEditing ? (
          <div className="pr-2">
            {/* Accessible label for the input */}
            <label htmlFor={`edit-conversation-${id}`} className="sr-only">
              Edit conversation name
            </label>
            <input
              id={`edit-conversation-${id}`} // Unique id for each input
              type="text"
              className="border p-1 rounded w-full"
              value={editValue}
              onChange={(e) => setEditValue(e.target.value)}
            />
          </div>
        ) : (
          <span className="text-gray-800">{text}</span>
        )}
      </div>
      <div className="flex space-x-2">
        {isEditing ? (
          <>
            <button
              onClick={handleSave}
              className="text-gray-700 hover:text-green-500 transition"
              title="Save"
            >
              <FiCheck />
            </button>
            <button
              onClick={handleCancel}
              className="text-gray-700 hover:text-red-500 transition"
              title="Cancel"
            >
              <FiX />
            </button>
          </>
        ) : (
          <>
            <button
              onClick={(e) => {
                e.stopPropagation(); // Prevent click from selecting the conversation
                setIsEditing(true);
              }}
              className="text-gray-700 hover:text-black transition"
              title="Edit"
            >
              <FiEdit />
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation(); // Prevent click from selecting the conversation
                onDelete();
              }}
              className="text-gray-700 hover:text-black transition"
              title="Delete"
            >
              <FiTrash />
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default ConversationItem;
